import React from "react"
import theme from "theme"
import { Theme, Text, Button, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"our-services"} />
      <Helmet>
        <title>Наші послуги | Дослідіть наші послуги в Sovix Elwin</title>
        <meta
          name={"description"}
          content={"Створення виняткового фітнес-досвіду"}
        />
        <meta
          property={"og:title"}
          content={"Наші послуги | Дослідіть наші послуги в Sovix Elwin"}
        />
        <meta
          property={"og:description"}
          content={"Створення виняткового фітнес-досвіду"}
        />
        <meta
          property={"og:image"}
          content={"https://win.sovixelwin.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://win.sovixelwin.com/img/4729230.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://win.sovixelwin.com/img/4729230.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://win.sovixelwin.com/img/4729230.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://win.sovixelwin.com/img/4729230.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://win.sovixelwin.com/img/4729230.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://win.sovixelwin.com/img/4729230.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="150px 0 150px 0"
        background="rgba(0, 0, 0, 0) url(https://win.sovixelwin.com/img/4.jpg) 0% 0% /cover repeat scroll padding-box"
        sm-padding="80px 0 80px 0"
        quarkly-title="Product-7"
      >
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          justify-content="flex-end"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="400px"
            background="#ffffff"
            padding="50px 35px 50px 35px"
            display="flex"
            align-items="center"
            flex-direction="column"
            md-padding="35px 35px 35px 35px"
          >
            <Text
              margin="0px 0px 20px 0px"
              font="normal 600 32px/1.2 --fontFamily-sans"
              text-align="center"
            >
              Наші послуги
            </Text>
            <Text
              margin="0px 0px 35px 0px"
              text-align="center"
              font="normal 400 18px/1.5 --fontFamily-sansHelvetica"
            >
              У Sovix Elwin ми прагнемо забезпечити комплексний фітнес-досвід,
              який охоплює всі аспекти вашого благополуччя. Наші послуги
              покликані підтримати вас на шляху до досягнення ваших цілей у
              сфері здоров'я та фітнесу. Від найсучаснішого обладнання до
              персоналізованих тренувальних програм - ми пропонуємо різноманітні
              послуги, щоб ваш час з нами був ефективним і приємним.
            </Text>
            <Button
              background="--color-darkL1"
              padding="12px 30px 12px 30px"
              border-radius="25px"
              href="/contacts"
              type="link"
              text-decoration-line="initial"
            >
              Відвідайте нас
            </Button>
          </Box>
        </Box>
      </Section>
      <Section
        background="#ffffff"
        href="/contacts"
        type="link"
        text-decoration-line="initial"
        padding="20px 0 110px 0"
        quarkly-title="Team-8"
      >
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          margin="70px 0px 0px 0px"
          grid-template-columns="repeat(2, 1fr)"
          grid-template-rows="repeat(2, 1fr)"
          lg-padding="0px 0 0px 0"
          lg-margin="50px 0px 0px 0px"
          lg-grid-gap="50px 0"
          sm-grid-template-columns="1fr"
          sm-grid-gap="40px 0"
          flex-direction="row"
          align-items="center"
          grid-gap="60px 40px"
          lg-grid-template-columns="1fr"
          lg-grid-template-rows="4fr"
          color="--darkL2"
        >
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            display="flex"
            flex-direction="row"
          >
            <Box
              min-width="100px"
              min-height="100px"
              width="40%"
              lg-width="30%"
              sm-width="100%"
            >
              <Image
                src="https://win.sovixelwin.com/img/5.jpg"
                display="block"
                width="auto"
                border-radius="25px"
                lg-max-width="100%"
                lg-width="100%"
                height="280px"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              width="60%"
              padding="0px 0px 0px 35px"
              lg-width="70%"
              lg-padding="0px 0px 0px 25px"
              sm-width="100%"
              sm-padding="0 0 0 0"
            >
              <Text
                margin="25px 0px 0px 0px"
                color="--dark"
                font="600 18px --fontFamily-sans"
                sm-margin="35px 0px 0px 0px"
              >
                Персональні тренування
              </Text>
              <Text
                margin="22px 0px 0px 0px"
                font="300 18px/140% --fontFamily-sansHelvetica"
                color="--darkL2"
              >
                Індивідуальні плани тренувань: Розроблені відповідно до ваших
                унікальних фітнес-цілей, вподобань та розкладу.
                <br />
                <br />
                Індивідуальні тренування: цілеспрямована підтримка наших
                сертифікованих тренерів, які допоможуть вам виконати кожну
                вправу з точністю та обережністю.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            sm-align-items="center"
            display="flex"
            flex-direction="row"
          >
            <Box
              min-width="100px"
              min-height="100px"
              width="40%"
              lg-width="30%"
              sm-width="100%"
            >
              <Image
                src="https://win.sovixelwin.com/img/6.jpg"
                display="block"
                width="auto"
                border-radius="25px"
                sm-width="100%"
                lg-max-width="100%"
                lg-width="100%"
                height="280px"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              width="60%"
              padding="0px 0px 0px 35px"
              lg-width="70%"
              sm-width="100%"
              sm-padding="0 0 0 0"
            >
              <Text
                margin="25px 0px 0px 0px"
                color="--dark"
                font="600 18px --fontFamily-sans"
                sm-margin="35px 0px 0px 0px"
              >
                Групові заняття з фітнесу
              </Text>
              <Text
                margin="22px 0px 0px 0px"
                font="300 18px/140% --fontFamily-sansHelvetica"
                color="--darkL2"
              >
                Динамічний спектр занять: Включає йогу, спін, HIIT та Zumba для
                всіх рівнів фізичної підготовки.
                <br />
                <br />
                Мотивація спільноти: Тренуйтеся в групі підтримки, яка заохочує
                вас розширювати свої межі.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            sm-align-items="center"
            display="flex"
            flex-direction="row"
          >
            <Box
              min-width="100px"
              min-height="100px"
              width="40%"
              lg-width="30%"
              sm-width="100%"
            >
              <Image
                src="https://win.sovixelwin.com/img/7.jpg"
                display="block"
                width="auto"
                border-radius="25px"
                sm-width="100%"
                lg-max-width="100%"
                lg-width="100%"
                height="280px"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              width="60%"
              padding="0px 0px 0px 35px"
              lg-width="70%"
              sm-width="100%"
              sm-padding="0 0 0 0"
            >
              <Text
                margin="25px 0px 0px 0px"
                color="--dark"
                font="600 18px --fontFamily-sans"
                sm-margin="35px 0px 0px 0px"
              >
                Оздоровчі програми
              </Text>
              <Text
                margin="22px 0px 0px 0px"
                font="300 18px/140% --fontFamily-sansHelvetica"
                color="--darkL2"
              >
                Рекомендації з харчування: Експертні поради щодо дієти та
                харчування, які доповнять вашу фітнес-рутину.
                <br />
                <br />
                Семінари з управління стресом: Техніки та практики для
                покращення психічного благополуччя та фізичного здоров'я.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            justify-self="center"
            sm-display="flex"
            sm-flex-direction="column"
            sm-align-items="center"
            display="flex"
            flex-direction="row"
          >
            <Box
              min-width="100px"
              min-height="100px"
              width="40%"
              lg-width="30%"
              sm-width="100%"
            >
              <Image
                src="https://win.sovixelwin.com/img/8.jpg"
                display="block"
                width="auto"
                border-radius="25px"
                sm-width="100%"
                lg-max-width="100%"
                lg-width="100%"
                height="280px"
                max-width="100%"
                object-fit="cover"
                max-height="100%"
              />
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              display="flex"
              flex-direction="column"
              width="60%"
              padding="0px 0px 0px 35px"
              lg-width="70%"
              lg-padding="0px 0px 0px 25px"
              sm-width="100%"
              sm-padding="0 0 0 0"
            >
              <Text
                margin="25px 0px 0px 0px"
                color="--dark"
                font="600 18px --fontFamily-sans"
                sm-margin="35px 0px 0px 0px"
              >
                Спеціалізовані семінари
              </Text>
              <Text
                margin="22px 0px 0px 0px"
                font="300 18px/140% --fontFamily-sansHelvetica"
                color="--darkL2"
              >
                Розвиток навичок: Семінари, присвячені конкретним
                фітнес-навичкам, таким як техніка підйому важкої атлетики або
                підготовка до марафону.
                <br />
                <br />
                Семінари зі здоров'я та велнесу: Навчальні заняття на різні теми
                - від профілактики травматизму до цілісного здоров'я.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Contact />
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
